<template>
  <div class="content_tt">
    <div class="header_uh">{{ pageinfo.orderId }}</div>
    <div class="content_tc">
      <div class="tcheaderCT022">
        <div class="tchT">
          <div>{{ pageinfo.currency }}</div>
          <!-- <div>20.00</div> -->
          <div>{{ pageinfo.money }}</div>
          <div>dollar</div>
        </div>
        <div class="tchT2">
          <img src="@/assets/images/qrcode/zhong.png" style="width:25px;" mode="widthFix"/>
          <div class="chTy">{{ dateFormation(date) }}</div>
        </div>
      </div>
      <div class="cent_tc20">
        <div class="centertc">
          <div class="center_bo" :class="{'active':isactive==0}" @click="choice(0)">Codigo QR</div>
          <div class="center_bo" :class="{'active':isactive==1}" @click="choice(1)">Change do PIX</div>
        </div>
        <div v-if="isactive==0">
          <div class="centertct1">
            <span>Collection code: </span>This is the QR code of<span> xiaoyun</span>. Please scan it
          </div>
          <div class="imgLG">
            <img :src="pageinfo.qrCodeSrc" class="imgLP" mode="widthFix" @longpress="saveBase64Img(img)"/>
          </div>
          <button @click="Tolmage()">Save QR code</button>
        </div>
        <div v-if="isactive==1">
          <div class="centertct1"><span>Collection codeFTAGB:</span>This is the QR code of<span>{{}}</span>. Please scan
            it
          </div>
          <div class="imgLG">
            <img :src="pageinfo.qrCodeSrc" class="imgLP" mode="widthFix" @longpress="saveBase64Img(img)"/>
          </div>
          <button @click="saveqr()">Save QR code</button>
        </div>
      </div>
      <div class="button_bott" @click="sdcosso()">Paguoi com sdcosso</div>
    </div>
    <!--    <u-mask :show="show" @click="show = false">-->
    <!--      <div class="showty1">Scan QR code for payment</div>-->
    <!--      <image src="" mode="widthFix" class="imgLY"/>-->
    <!--      <div class="showty3">Long press to save QR code</div>-->
    <!--    </u-mask>-->
  </div>
</template>

<script>

import {getpayOrderId} from '@/api/qrcode'
// import config from "@/config";

export default {
  data() {
    return {
      isactive: 0,
      payOrderId: 0,
      pageinfo: {},
      show: false,
      postType: 0,
      postInfomation: [],
      memberId: '',
      img: '',
      date: new Date().toISOString(),
    }
  },

  created() {
    console.log(this.$route.params.id)
    this.payOrderId = this.$route.params.id;
    this.getpayData();
  },
  mounted() {
    let _this = this;
    setInterval(function () {
      _this.date = Date.parse(new Date());
    }, 1000);
  },
  methods: {
    saveqr() {
      console.log("save QR");
      this.show = true;
    },
    choice(type) {
      this.isactive = type;
    },
    getpayData() {
      console.log("订单号：" + this.payOrderId);
      getpayOrderId(this.payOrderId).then(res => {
        this.pageinfo = res
      }).catch(res => {
        console.log(res);
        // this.$router.push({name: config.errorPageRouteName, params: {errInfo: res.msg}})
      });
    },
    //截图保存图片
    Tolmage() {
      console.log("Tolmage")
      // let that = this
      // this.show = false
      // setTimeout(() => {
      //   //#ifndef H5
      //   var pages = getCurrentPages();
      //   console.log(pages)
      //   var page = pages[pages.length - 1];
      //   var bitmap = null;
      //   console.log(page)
      //   var currentWebdiv = page.$getAppWebdiv();
      //   bitmap = new plus.nativeObj.Bitmap('amway_img');
      //   // 将web内容绘制到Bitmap对象中
      //   currentWebdiv.draw(
      //       bitmap,
      //       function () {
      //         bitmap.save(
      //             '_doc/a.jpg', {},
      //             function (i) {
      //               uni.saveImageToPhotosAlbum({
      //                 filePath: i.target,
      //                 success: function () {
      //                   bitmap.clear();
      //                   uni.showToast({
      //                     title: '保存图片成功',
      //                     icon: 'none',
      //                     mask: false,
      //                     duration: 1500
      //                   });
      //                   that.show = true
      //                 }
      //               });
      //             },
      //             function (e) {
      //               // that.show = true
      //               console.log('保存失败', +JSON.stringify(e))
      //             }
      //         );
      //       },
      //       function (e) {
      //         // that.show = true
      //         console.log('截屏失败', JSON.stringify(e))
      //       }
      //   );
      //   //#endif
      // }, 500)
    },

    sdcosso() {
      if (this.pageinfo.qrCodeSrc == '' || this.pageinfo.qrCodeSrc == undefined) {
        this.$message({
          message: 'Please reload the data',
          center: true,
          iconClass: 'none'
        });
      } else {
        this.$router.push({name: "qrcodeOrderIdpushPage", params: {id: this.payOrderId}})
      }
    },
    dateFormation(time) {
      const date = new Date(time);
      // const year = date.getFullYear();
      // const month = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1;
      // const day = date.getDay() < 10 ? '0' + date.getDay() : date.getDay();
      const hours = date.getHours() < 10 ? '0' + date.getHours() : date.getHours();
      const minutes = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();
      // const seconds = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();
      return hours + ":" + minutes;
    }
  }
}
</script>

<style scoped>
.content_tt {
  background-color: rgba(200, 181, 245, 0.2);
  height: 100vh;
  /* padding:10px 30px; */
}

.header_uh {
  background-color: #724ec6;
  color: #fff;
  text-align: center;
  height: 90px;
  line-height: 90px;
  font-size: 40px;
}

.content_tc {
  padding: 30px 20px;
}

.tcheaderCT022 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.tchT, .tchT2 {
  display: flex;
  align-items: center;
}

.tchT > div {
  margin: 5px;
}

.tchT > div:nth-child(1) {
  font-size: 30px;
  font-weight: 540;
}

.tchT > div:nth-child(2) {
  font-size: 45px;
  font-weight: 700;
}

.tchT > div:nth-child(3) {
  background-color: #724ec6;
  font-size: 15px;
  padding: 1px 4px;
  border-radius: 10px;
  color: #fff;
}

.chTy {
  font-size: 34px;
  color: #e71313;
}

.cent_tc20 {
  box-shadow: 2px 2px 10px rgba(200, 181, 245, 0.3);
  border-radius: 10px;
  padding: 30px 30px 40px;
  margin: 40px 0px 50px;
}

.centertc {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: rgba(200, 181, 245, 0.5);
  border-radius: 10px;
}

.center_bo {
  width: 50%;
  height: 0.8rem;
  line-height: 0.8rem;
  border-radius: 10px;
  background-color: rgba(200, 181, 245, 0.5);
  /*padding: 10px 20px;*/
  text-align: center;
  color: #724ec6;
  font-size: 0.4rem;
}

.centertct1 {
  font-size: 30px;
  margin: 10px 0px;
}

.centertct1 span {
  color: #724ec6;
  margin: 0px 10px;
}

.imgLG {
  padding: 50px 10% 0;
}

button {
  width: 300px;
  background-color: #724ec6;
  border: 0;
  border-radius: 30px;
  color: #fff;
  font-size: 0.4rem;
  height: 60px;
  line-height: 60px;
}

.button_bott {
  color: #fff;
  font-size: 0.4rem;
  background-color: #724ec6;
  border-radius: 5px;
  text-align: center;

  padding: 20px 0;
  margin: 90px 0;
}

.active {
  background-color: #724ec6;
  color: #fff;
}

.imgLP {
  border: 1px solid #724ec6;
  width: 500px;
  height: 800px;
  border-radius: 10px;
}


</style>
