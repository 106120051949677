import request from '@/http/request'


export function getpayOrderId(payOrderId) {
    return request.request({
        url: '/api/queryqrCodeMoney/' + payOrderId,
        method: 'GET',
    })
}


